.wrapper {
  margin-bottom: 50px;
}

.title {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
  margin-bottom: 30px;
}

.large {
  position: relative;
}

.background {
  position: relative;
  top: 0;
  left: -31px;
  font-size: 358px;
  color: white;
  line-height: 400px;
  user-select: none;
  font-weight: bold;
}

.info {
  position: absolute;
  bottom: 25px;
  display: flex;
  padding-right: 150px;
  right: 0;
  align-items: center;
}

.text {
  margin-bottom: 14px;
  line-height: 17px;
  width: 391px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.cards {
  display: flex;
  justify-content: space-between;
}

.subtitle {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
  margin-bottom: 50px;
}

.description {
  margin-right: 70px;
}

.buttons {
  width: min-content;
}

@media (max-width: 1279px) {
  .background {
    font-size: 278px;
    line-height: 300px;
    left: -21px;
    text-align: center;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 0 75px;
    justify-items: center;
  }

  .info {
    padding-right: 75px;
  }

  .subtitle {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .description {
    margin-right: 30px;
  }

  .text {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 10px;
    width: 240px;
  }

  .title {
    padding-left: 20px;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    margin-bottom: 0;
  }

  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    padding: 0px 20px;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 0;
  }

  .background {
    font-size: 125px;
    line-height: 150px;
    left: -21px;
    text-align: center;
  }

  .description {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .text {
    width: auto;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .large {
    padding: 0;
  }

  .buttons {
    width: auto;
  }

  .info {
    position: initial;
    display: block;
    padding: 0px 20px;
    transform: translateY(-70px);
  }

  .button {
    margin: 0 auto;
  }
}
