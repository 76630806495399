.wrapper {
  display: flex;
  min-height: 100vh;
  // height: calc(100vh - 318px);
}

.sidebar {
  padding: 40px;
  border-right: 1px solid #c4c4c4;
}

.content {
  padding: 40px;
  width: 100%;
  overflow-y: auto;
}

.links {
  margin-bottom: 50px;
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: #727171;
  margin-bottom: 16px;
  white-space: nowrap;

  & svg {
    margin-right: 16px;
    width: 21px;
    height: 21px;

    & path {
      fill: #c4c4c4;
    }
  }

  &:hover {
    color: black;

    & path {
      fill: #3bb46c;
    }
  }
}

.active {
  color: black;

  & svg {
    & path {
      fill: #3bb46c;
    }
  }
}

.kyc {
  display: flex;
  background-color: white;
  border-radius: 61px 0px 0px 0px;
  padding: 35px 20px 25px 20px;
  border: 1px solid #e3e4e4;
  width: 310px;
}

.icon {
  margin-right: 18px;
  font-size: 31px;

  & svg {
    color: #3bb46c;
  }
}

.red {
  & svg {
    color: #d75033;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 16px;
}

.status {
  color: #a5a5a5;
  margin-bottom: 14px;
}

.button {
  width: 100%;
}

@media (max-width: 1279px) {
  .wrapper {
    min-height: 100vh;
    // height: calc(100vh - 255px);
  }

  .sidebar {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    height: auto;
  }

  .content {
    padding: 30px;
  }

  .sidebar {
    padding: 10px;
  }

  .link {
    & svg {
      margin-right: 0;
    }

    & div {
      display: none;
    }
  }

  .title {
    font-size: 14px;
    line-height: 17px;
  }

  .status {
    font-size: 10px;
    line-height: 12px;
  }

  .kyc {
    padding: 30px 15px;
    width: 100%;
  }
}
