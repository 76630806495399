.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 36px;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background: white;
}

.links {
  display: flex;
  padding: 0px 15px;
  border-right: 0.5px solid #e9ebfb;
}

.logo {
  width: 131px;
}

.controls {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.icon {
  margin-right: 10px;

  &:hover {
    fill: #276df1;
  }
}

.link {
  display: block;
  color: black;
  cursor: pointer;
  position: relative;
  font-weight: bold !important;
  font-size: 13px !important;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  & svg {
    margin-right: 8px;
    font-size: 20px;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    color: #276df1;

    & svg {
      color: #276df1;
    }
  }
}

.text {
  display: block;
}

.avatar {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
  }
}

.dropdown {
  cursor: pointer;
  padding-left: 15px;
  text-transform: uppercase;
  z-index: 99999;
}

.item {
  text-transform: uppercase;
}

.icon {
  margin-left: 5px;
}

.navigation {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding-right: 15px;
  border-right: 0.5px solid #e9ebfb;

  & > div {
    margin-right: 24px;
    transition: all 0.3s ease-in-out;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      color: #276df1;
    }
  }
}

@media (max-width: 1279px) {
  .avatar {
    width: 28px;
    height: 28px;
  }

  .wrapper {
    padding: 18px 10px;
  }

  .logo {
    width: 110px;
  }

  .dropdown {
    margin-left: 6px;
  }

  .navigation {
    font-size: 11px;
  }

  .text {
    display: none;
  }
}

@media (max-width: 767px) {
  .logo {
    width: 89px;
  }

  .navigation {
    display: none;
  }
}
