:global {
  .react-viewer-btn {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .react-viewer-close {
    z-index: 2010 !important;
  }

  .react-viewer-icon:hover {
    color: #3bb46c !important;
  }

  .react-viewer-navbar {
    background-color: #f1f2f6 !important;
  }

  [data-key='reset'] {
    display: none !important;
  }
}

.wrapper {
  padding: 40px 37px;
  background-color: #f1f2f6;
}

.banner {
  display: none;
}

.top {
  margin-bottom: 30px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.overlay {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}

.slider {
  position: relative;
  margin-bottom: 40px;
}

.count {
  display: none;
}

.gallery {
  position: absolute;
  cursor: pointer;
  right: 22px;
  padding: 9px 18px;
  bottom: 20px;
  background-color: #3bb46c;
  color: white;
  border-radius: 25px;

  & svg {
    margin-right: 7px;
  }
}

.chip {
  line-height: 13px;
  padding: 4px 14px;
  margin-right: 12px;
  margin-bottom: 10px;
  font-size: 11px;
  color: #3bb46c;
  border: 1px solid #3bb46c;
  border-radius: 25px;
}

.statusMobile {
  display: none;
}

.icons {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  display: flex;
  color: #3bb46c;

  &:first-of-type {
    margin-right: 40px;
    color: #727171;
  }

  &:last-child{
    cursor: pointer;
    margin-left: auto;

    & span {
      display: inline;
    }
  }

  & svg {
    margin-right: 4px;
  }
}

a.icon {
  color: #276DF1 !important;
  margin-left: 25px;

  @media (max-width: 1279px) {

    span {
      display: none;
    }
  }
}

.invest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a5a5a5;
  font-size: 14px;
}

.button {
  margin-left: 10px;
}

@media (max-width: 1279px) {
  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .wrapper {
    padding: 20px;
  }

  .banner {
    padding: 16px 30px;
    border: 1px solid #ececec;
    display: block;
    color: black;
    background-color: white;
    position: sticky;
    bottom: 0;
  }

  .hide {
    display: none;
  }

  .slider {
    margin-bottom: 0px;
    margin-left: 0px;
    width: calc(100% - 310px);
  }

  .titleWrapper {
    display: block;
  }

  .title {
    display: inline;
    vertical-align: middle;
  }

  .icon {
    font-size: 10px;

    &:first-of-type {
      margin-right: 24px;
    }

    &:last-child {
      & span {
        display: none;
      }
    }
  }

  .count {
    display: flex;
    align-items: center;
    font-size: 11px;
    position: absolute;
    cursor: pointer;
    right: 15px;
    bottom: 10px;
    padding: 2px 7px;
    background-color: #727171;
    color: white;
    border-radius: 25px;

    & svg {
      margin-right: 6px;
    }
  }

  .gallery {
    display: none;
  }

  .invest {
    font-size: 11px;
  }

  .statusMobile {
    display: block;
  }
}

@media (max-width: 767px) {
  .statusMobile {
    margin: 0 auto 20px auto;
  }

  .slider {
    margin-bottom: 20px;
    margin-left: -20px;
    width: 100vw;
  }

  .info {
    display: flex;
    flex-direction: column;
  }
}
