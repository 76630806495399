.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  border-radius: 61px 0px 0px 0px;
  max-width: 745px;
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 24px;
}

.icon {
  margin-bottom: 24px;
}

.action {
  display: flex;
  font-size: 12px;
  margin-top: 30px;

  & div:first-child {
    margin-right: 7px;
  }
}

.text {
  margin-bottom: 30px;
  text-align: center;
  width: 350px;
  line-height: 17px;
}

.inputWrapper {
  position: relative;
  margin-bottom: 54px;
}

.input {
  display: block;
  width: 222px;
}

.inputError {
  color: #d75033;
  border-bottom-color: #d75033;

  &::placeholder {
    color: #d75033;
  }
}

.errorsMobile {
  display: none;
}

.error {
  display: flex;
  position: absolute;
  top: 0;
  right: -16px;
  transform: translateX(100%) translateY(0%);
  line-height: 17px;
  background-color: #e9ebfb;
  padding: 10px 20px;
  border-radius: 26px 0px 0px 0px;
}

.circle {
  min-width: 11px;
  max-width: 11px;
  min-height: 11px;
  max-height: 11px;
  background-color: #d75033;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
}

.password {
  display: flex;
  justify-content: space-between;
}

.row {
  margin-bottom: 20px;
}

.small {
  width: 111px;
}

.button {
  width: 222px;
}

.link {
  color: #276df1;
  font-size: 12px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;

  &:hover {
    color: #276df1;
    border-bottom: 2px solid #276df1;
  }
}

.resendLink {
  color: #276df1;
  font-size: 12px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
  cursor: pointer;
  width: fit-content;
  margin-top: 10px;

  &:hover {
    color: #276df1;
    border-bottom: 2px solid #276df1;
  }
}

@media (max-width: 1279px) {
  .error {
    display: none;
  }

  .text {
    width: 100%;
  }

  .errorsMobile {
    display: block;
    line-height: 17px;
    width: 222px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
  }

  .errorMobile {
    display: flex;
    font-size: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}
