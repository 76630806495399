@import 'styles/colors';
@import 'styles/mixins';

.wrapper{
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-grey;
}

.card{
  @include rounded-left-border;

  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  max-width: 745px;
  width: 100%;
  position: relative;
}

.stepper {
  margin-bottom: 30px;

  @media screen and (max-width: 767px){
    margin: 30px 0;
  }
}

.step {
  font-size: 8px;
}

.form {
  width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form.disable{
  max-width: 330px;
  width: 100%;
  justify-content: center;
}

.title{
  @include font-heading-l3;
  margin-bottom: 16px;
  text-align: center;
}

.text {
  text-align: center;
  margin: 20px 0 30px;
}

.qrcode {
  margin-right: 28px;

  @media screen and (max-width: 600px){
    margin-right: 0;
  }

  img{
    width: 160px;
    height: 160px;
  }
}

.buttons {
  display: flex;
  margin-bottom: 50px;
}

.resend__button{
  @include font-body;

  display: block;
  margin: 26px auto 0;
  padding: 8px 5px;
  color: $secondary;
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
}

.code {
  display: flex;
  margin-bottom: 50px;

  @media screen and (max-width: 600px){
    flex-wrap: wrap;
    justify-content: center;
  }
}

.button {
  width: 222px;
}

.warning {
  color: #727171;
  margin-bottom: 20px;
  line-height: 17px;

  @media screen and (max-width: 600px){
    margin: 20px 0;
  }
}

.backup {
  min-width: 93px;
  max-width: 93px;
  margin-right: 28px;
}

.inputs {
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;

  & div.first {
    margin-bottom: 30px;
  }
}

.inputWrapper {
  display: flex;
  position: relative;
}

.secondInputWrapper{
  position: relative;
}

.input {
  width: 100%;
}

.info {
  margin-top: 6px;
  font-size: 8px;
  line-height: 10px;
  color: #a5a5a5;
}

.back{
  position: absolute;
  display: flex;
  align-items: center;
  left: 20px;
  top: 41px;
  cursor: pointer;

  @media screen and (max-width: 770px){
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover{
    text-decoration: underline;
  }

  &__icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $secondary;
    position: relative;

    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__text{
    padding: 0 0 0 7px;
    color: $secondary;
    @include font-body;
  }
}