@import 'styles/mixins';
@import 'styles/colors';

.card {
  @include rounded-left-border;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 35px 20px 25px 20px;
  border: 1px solid $border-color;
  max-width: 310px;
  width: 100%;

  &__header {
    display: flex;
  }
}

.icon {
  margin-right: 18px;
  font-size: 31px;

  & svg {
    color: $primary;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: $black;
}

.select {
  border: none;
  border-bottom: 2px solid #c4c4c4;
  width: 80px;
  background-color: transparent;
  text-align: center;
  outline: none;
  margin: 30px auto 0;
}

@media (max-width: 767px) {
  .title {
    font-size: 14px;
    line-height: 17px;
  }
}
