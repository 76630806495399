@import '~antd/dist/antd.css';
@import './fonts.scss';
@import './rewrite.scss';
@import './colors.scss';
@import './mixins.scss';

body {
  overflow-y: overlay;
  overflow-x: hidden;
  height: auto;
  margin: 0;
  background-color: $background-grey;
  font-family: 'Museo Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ecedee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey;

    &:hover {
      background-color: $primary;
    }
  }
}

code {
  font-family: 'Museo Sans';
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  padding: 0 0 0 15px;
  list-style-position: outside;
  margin-bottom: 24px;
}

.accreditation-title {
  margin: 0 0 15px;
}
