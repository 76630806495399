.copy {
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
    width: 100%;
    cursor: pointer;
    border-radius: 25px;
    padding: 8px 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  
    & svg {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
}