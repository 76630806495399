.wrapper {
  display: grid;
  grid-template-columns: 32% 20% 15% 15% 18%;
  padding: 20px 0;
  border-bottom: 1px solid #a5a5a5;

  & div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
}

.link {
  color: #276df1;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
  white-space: nowrap;

  &:hover {
    color: #276df1;
  }

  & svg {
    margin-right: 10px;
  }
}

.remove {
  color: #d75033;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: #d75033;
  }

  & svg {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #a5a5a5;

    & div {
      padding-right: 0px;
    }
  }

  .name {
    margin-bottom: 15px;
  }

  .actions {
    margin-top: 10px;
  }
}
