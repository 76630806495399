.error {
    display: flex;
    position: absolute;
    top: 0%;
    right: -16px;
    transform: translateX(100%) translateY(0%);
    line-height: 17px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
}

.circle {
    min-width: 11px;
    max-width: 11px;
    min-height: 11px;
    max-height: 11px;
    background-color: #d75033;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 3px;
}

@media (max-width: 800px) {
    .error {
        display: none;
    }
    
    .error.mobile {
        display: block;
        line-height: 17px;
        background-color: #e9ebfb;
        padding: 10px 20px;
        border-radius: 26px 0px 0px 0px;
    }
}