.wrapper {
  background-color: white;
  border-radius: 61px 0px 0px 0px;
  min-width: 438px;
  max-width: 438px;
  height: fit-content;
  margin-left: 25px;
  position: sticky;
  top: 90px;
}

.top {
  padding: 40px 45px 0px 45px;
}

.bottom {
  padding: 0px 45px 24px 45px;
}

.raised {
  line-height: 17px;
  color: #a5a5a5;
}

.total {
  color: #276df1;
  font-size: 44px;
  line-height: 52px;
}

.bar {
  border-radius: 13px;
  background-color: #f1f2f6;
  position: relative;
  overflow: hidden;
  height: 8px;
  user-select: none;
  margin-bottom: 16px;

  & div {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 13px 0px 0px 13px;
    color: #3bb46c;
    height: 100%;
    background-color: #276df1;
  }
}

.terms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.large {
  font-size: 36px;
  line-height: 52px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
}

.investors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #e3e3e4;
  margin: 16px 0px 26px 0px;
}

.invest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a5a5a5;
  font-size: 14px;
}

.button {
  margin: -10px 0 0 10px;
}

.error {
  display: block;
  padding: 5px 0 0;
  color: tomato;
}

@media (max-width: 1279px) {
  .wrapper {
    position: initial;
    min-width: 280px;
    margin-left: 0;
  }

  .raised {
    font-size: 11px;
    line-height: 13px;
    color: #a5a5a5;
  }

  .top {
    padding: 24px 20px 0px 20px;
  }

  .bottom {
    padding: 0px 20px 20px 20px;
  }

  .total {
    font-size: 26px;
    line-height: 31px;
  }

  .invest {
    font-size: 11px;
  }

  .terms {
    font-size: 11px;
  }

  .divider {
    margin: 16px 0px 16px 0px;
  }

  .large {
    font-size: 26px;
    line-height: 31px;

    & svg {
      height: 20px;
    }
  }
}
