:global {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    user-select: none;
  }

  .react-pdf__Page__textContent {
    width: 100% !important;
    height: auto !important;
    user-select: none;
  }

  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.wrapper {
  display: flex;
}

.container {
  margin-bottom: 40px;
}

.img {
  width: 100%;
  margin-bottom: 30px;
}

.header {
  margin-bottom: 25px;
  font-size: 30px;
}

.content {
  width: 100%;
  padding: 40px 37px 0px 37px;
  margin-left: -37px;
  border-radius: 0px 21px 0px 0px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.05) 100%);
  background-size: 100% 400px;
  background-repeat: no-repeat;
}

.item {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

.block {
  line-height: 17px;
  margin-bottom: 20px;
  color: #727171;
  white-space: pre-line;
}

.title {
  font-size: 30px;
  margin-bottom: 30px;
}

.name {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.statusDesktop {
  display: block;
}

.documents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 30px;
  column-gap: 80px;
}

.document {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.download {
  color: #3bb46c !important;
  font-size: 25px;
  cursor: pointer;
  margin-left: auto;
  margin-top: 10px;
  z-index: 2;
}

.image {
  min-width: 280px;
  cursor: pointer;

  & img {
    width: 100%;
  }
}

.tagline {
  font-size: 30px;
  line-height: 30px;
  margin: 25px 0;
}

.links {
  display: flex;
  align-items: center;
}

.link {
  cursor: pointer;
  transition: all 0.2s linear;
  color: #727171;
  font-weight: bold;
  padding: 12px 18px;
  border-radius: 12px 12px 0px 0px;

  &:hover {
    color: black;
  }
}

.active {
  background-color: white;
  color: black;
}

.playerWrapper {
  position: relative;
  padding-top: 56.25%;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1279px) {
  .statusDesktop {
    display: none;
  }

  .wrapper {
    display: block;
  }

  .block {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 15px;
  }

  .content {
    width: 100vw;
    padding: 20px 30px 0px 30px;
    margin-left: -20px;
    border-radius: 0px;
  }

  .title {
    margin-bottom: 20px;
  }

  .header {
    margin-bottom: 15px;
  }

  .link {
    padding: 10px;
    font-size: 10px;
    line-height: 12px;
  }
}

.shortDescription {
  padding: 60px 40px 50px 20px;

  .inner {
    border-left: 1px solid #3BB46C;
    padding: 20px 0 20px 40px;
    color: #4F4F4F;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}