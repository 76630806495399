.carousel {
  position: relative;
}

.container {
  border-radius: 61px 0px 0px 0px;
}

.item {
  display: flex;
  justify-content: center;
}

.image {
  min-width: 280px;
  max-height: 280px;
  cursor: pointer;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1279px) {
  .container {
    border-radius: 0;
  }

  .image {
    max-height: 266px;
  }
}

@media (max-width: 767px) {
  .image {
    max-height: 200px;
  }
}
