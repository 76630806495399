@import 'styles/colors';
@import 'styles/mixins';

.tooltip{
    @include font-heading-l3;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $grey;
    text-align: center;
    color: $white;
    cursor: pointer;

    &__content{
        @include font-body;

        border-radius: 5px;
        opacity: 0;
        position: absolute;
        top: -65px;
        left: 50%;
        transform: translateX(-50%) translateY(10px);
        width: 150px;
        padding: 5px;
        color: $white;
        transition: .3s;
        background: $grey;
    }

    &:hover &__content{
        opacity: 1;
        transform: translateX((-50%)) translateY(0px);
    }
}