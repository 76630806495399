.input {
  background-color: transparent;
  outline: none;
  padding: 9px 0;
  border: 0px;
  color: black;
  font-size: 14px;
  border-bottom: 1px solid black;
  border-radius: 0;
  transition: 0.3s ease-out all;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    color: black;
    border-bottom: 1px solid #276df1;

    &::placeholder {
      color: #757575;
    }
  }

  &:focus {
    color: black;
    border-bottom: 1px solid #276df1;

    &::placeholder {
      color: #757575;
    }
  }
}

.wrapper {
  position: relative;
  display: contents;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  user-select: none;
  color: #727171;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  &:hover {
    color: rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
