.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.inputs {
  display: flex;
  margin-bottom: 10px;

  & div:first-child {
    margin-right: 40px;
  }
}

.input {
  display: block;
  width: 222px;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.inputError {
  color: #d75033;
  border-bottom-color: #d75033;

  &::placeholder {
    color: #d75033;
  }
}

.errorsMobile {
  display: none;
}

.error {
  display: flex;
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateX(100%) translateY(-50%);
  line-height: 17px;
  background-color: #e9ebfb;
  padding: 10px 20px;
  border-radius: 26px 0px 0px 0px;
}

.circle {
  min-width: 11px;
  max-width: 11px;
  min-height: 11px;
  max-height: 11px;
  background-color: #d75033;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
}

.button {
  width: 222px;
}

@media (max-width: 1279px) {
  .error {
    display: none;
  }

  .errorsMobile {
    display: block;
    line-height: 17px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
  }

  .errorMobile {
    display: flex;
    font-size: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}
