.wrapper {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f2f6;
}

.content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  border-radius: 61px 0px 0px 0px;
  max-width: 745px;
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 16px;
}

.icon {
  margin-bottom: 16px;
}

.green {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 30px;
  text-align: center;
  color: #3bb46c;
}

.text {
  margin-bottom: 40px;
  text-align: center;
  width: 230px;
  line-height: 17px;
}

.button {
  width: 222px;
}

@media (max-width: 1279px) {
  .wrapper {
    padding: 10px 20px 40px 20px;
  }
}
