.primary {
  background-color: #3bb46c;
  color: white;
  border: 1px solid #3bb46c;
  border-radius: 25px;
  font-size: 14px;
  transition: all 0.2s linear;
  user-select: none;
  display: block;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  font-weight: 300;
  outline: none;
  text-align: center;
  white-space: nowrap;

  &:hover {
    background-color: transparent;
    color: #3bb46c;
  }
}

.inverted {
  background-color: transparent;
  color: #3bb46c;

  &:hover {
    background-color: #3bb46c;
    color: white;
  }
}

.secondary {
  background-color: #276df1;
  border: 1px solid #276df1;

  &:hover {
    background-color: transparent;
    color: #276df1;
  }
}

.secondaryInverted {
  background-color: transparent;
  color: #276df1;

  &:hover {
    background-color: #276df1;
    color: white;
  }
}

.error {
  background-color: #d75033;
  border: 1px solid #d75033;

  &:hover {
    background-color: transparent;
    color: #d75033;
  }
}

.errorInverted {
  background: transparent;
  border: 1px solid #d75033;
  color: #d75033;

  &:hover {
    background-color: #d75033;
    color: #fff;
  }
}

.lg {
  padding: 8px 37px;
}

.md {
  padding: 8px 23px;
}

.sm {
  padding: 4px 15px;
}

.primary:disabled,
.primary[disabled] {
  border: 1px solid #c4c4c4;
  background-color: #c4c4c4;
  color: white;
  cursor: default;
}

@media (max-width: 1279px) {
  .primary {
    font-size: 11px;
  }

  .lg {
    padding: 8px 28px;
  }
}
