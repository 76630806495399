.wrapper {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f2f6;
}

.stepper {
  width: 350px;
  margin-bottom: 30px;
}

.content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  border-radius: 61px 0px 0px 0px;
  max-width: 745px;
  width: 100%;
}

.step {
  font-size: 8px;
}

.form {
  width: 222px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.action {
  display: flex;
  font-size: 12px;
  margin-top: 30px;

  & div:first-child {
    margin-right: 7px;
  }
}

.resend {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin-top: 30px;
}

.link {
  color: #276df1;
  font-size: 12px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: #276df1;
    border-bottom: 2px solid #276df1;
  }
}

.warning {
  text-align: center;
  max-width: 450px;
}

.icon {
  margin-bottom: 20px;
}

@media (max-width: 1279px) {
  .wrapper {
    padding: 10px 20px 40px 20px;
  }
}
