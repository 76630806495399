@import 'styles/mixins';
@import 'styles/colors';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000000;
}

.range {
  display: flex;
  justify-content: center;
  height: 35px;
}

.diagramWrapper {
  padding: 17px 17px 17px 27px;
  background-color: rgba(#ffffff, 0.4);
  border-radius: 20px
}