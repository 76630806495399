.container {
    padding: 40px 0;
    margin: 0 30px;
    width: 100%;
}

.adminTable {
    background: transparent;

    .ant-table{
        background: transparent !important;
    }
}

.layout {
    display: flex;

    .navigation {
        display: flex;
        flex-direction: column;
        padding: 30px;
        align-items: center;
        border-right: 1px solid #C4C4C4;
    }

    .link {
        margin: 0 0 25px 0;
        display: flex;
        align-items: center;
        width: 120px;
        cursor: pointer;
        transition: .3s;

        &:hover{
            span {
                color: #000;
            }

            svg {
                path {
                     fill: #3BB46C;
                }
            }
        }

        span {
            margin: 0 0 0 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #727171;
        }
    }

    .link.active {
        span {
            color: #000;
        }

        svg {
            path {
                fill: #3BB46C;
            }
        }
    }
}

.error {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: tomato;
}

.projects {
    .flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .part {
        width: 45%;
    }

    .field {
        margin: 20px 0;

        .select { 
            width: 200px;
        }

        .label {
            display: block;
            margin: 0 0 20px 0;
            font-size: 16px;
        }
    }

    .media {
        padding: 30px 0 0;
        width: 100%;
    }

    .uploader {
        margin: 20px 0 0;
        display: flex;
        justify-content: space-between;
    }
}

.user {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            margin: 0;
        }

        .text{
            font-size: 14px;
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;

        .button {
            width: 50%;
        }
    }

    .documents {
        margin: 30px 0;

        .heading {
            font-size: 30px;
            margin: 5px 0;
        }

        .document {
            max-width: 400px;
            margin: 10px 0;
            padding: 10px;
            display: block;
            font-size: 18px;

            .title {
                padding: 0 0 0 10px;
            }
        }
    }

    .table {
        padding: 50px 0;
    }
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    
    .select {
        width: 200px;
    }

    .date {
        width: 250px;
    }

    h3 {
        margin: 0 0 20px 0;
    }

    .container {
        width: 25%;
        margin: 0 20px;
    }
}

.searchContainer.searchContainerProjects {
    flex-wrap: wrap;

    .container {
        width: 25%;
        margin: 0;
    }
}

.documents {
    .select {
        width: 200px;
        margin: 20px 0;
    }
}