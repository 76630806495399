.left {
  color: black;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(50%) translateY(-60px);
  border-radius: 50%;
  background-color: #e9ebfb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
}

.right {
  color: black;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(50%) translateY(-60px);
  border-radius: 50%;
  background-color: #e9ebfb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1279px) {
  .right {
    width: 36px;
    height: 36px;
    right: 0;
  }

  .left {
    width: 36px;
    height: 36px;
    left: 0;
  }

  .wrapper {
    display: none;
  }
}
