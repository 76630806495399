@import 'styles/mixins';
@import 'styles/colors';

:global {
  .selected-flag {
    padding-left: 0 !important;
  }

  .ant-select-selector {
    border: 0px !important;
    border-bottom: 1px solid black !important;
    outline: none !important;
    box-shadow: none !important;
    padding-left: 0px !important;
  }

  .ant-select-selection-placeholder {
    color: #757575 !important;
  }

  .ant-select-selection-search {
    left: 0 !important;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: center;
}

.row {
  display: flex;

  & div:not(:last-child) {
    margin-right: 30px;
  }
}

.select {
  width: 200px !important;
  margin-top: 9px !important;
  height: fit-content !important;
}

.input {
  display: block;
  width: 100%;
}

.dropdown {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-bottom: 1px !important;
}

.phoneInput {
  width: 100% !important;
  min-width: 205px !important;
  border: 0 !important;
  margin-top: 6px !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  color: black !important;
  padding-left: 40px !important;

  transition: 0.3s ease-out all !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  &:hover {
    color: black !important;
    border-bottom: 1px solid #276df1 !important;

    &::placeholder {
      color: #757575 !important;
    }
  }

  &:focus {
    color: black !important;
    border-bottom: 1px solid #276df1 !important;

    &::placeholder {
      color: #757575 !important;
    }
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  margin: 0 auto;
}

.checkbox {
  font-size: 11px !important;
  align-items: flex-start !important;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.inputError {
  color: #d75033 !important;
  border-bottom-color: #d75033 !important;

  &::placeholder {
    color: #d75033 !important;
  }
}

.errorsMobile {
  display: none;
}

.error {
  display: flex;
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateX(100%) translateY(-50%);
  line-height: 17px;
  background-color: #e9ebfb;
  padding: 10px 20px;
  border-radius: 26px 0px 0px 0px;
}

.circle {
  min-width: 11px;
  max-width: 11px;
  min-height: 11px;
  max-height: 11px;
  background-color: #d75033;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
}

.companyButton {
  margin: 30px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 14px 22px;
  cursor: pointer;
  font-size: 12px;

  & svg {
    margin-left: 10px;
  }
}

.button {
  width: 222px;
  margin: 0 auto;
  margin-top: 30px;
}

.link {
  color: #276df1;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-out;

  &:hover {
    color: #276df1;
    border-bottom: 1px solid #276df1;
  }
}

.alert{
  @include font-heading-l3;

  border-radius: 5px;
  margin: 10px 0 20px;
  padding: 15px;
  font-size: 16px;
  text-align: center;
  background: #d75033;
  color: $white;
}

@media (max-width: 1279px) {
  .error {
    display: none;
  }

  .errorsMobile {
    display: block;
    line-height: 17px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
  }

  .errorMobile {
    display: flex;
    font-size: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 767px) {
  .row {
    display: block;

    & div:not(:last-child) {
      margin-right: 0px;
    }
  }

  .select {
    width: 100% !important;
  }
}
