.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 60px;
}

.opened {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(115, 115, 115, 0.5);
  z-index: 113;
}

.modal {
  position: absolute;
  top: 50%;
  border-radius: 61px 0px 0px 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px;
  background: #ffffff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 50px;
  width: 750px;
  min-height: 563px;
  max-height: 90vh;
  z-index: 3;
}

.input {
  width: 100%;
  margin-bottom: 20px;
}

.button {
  width: 222px;
}

.form {
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.body {
  overflow: hidden;
}

.progressWrapper {
  background: #f1f2f6;
  width: 100%;
  margin-top: 60px;
  padding: 50px 24px;
  border-radius: 61px 0px 0px 0px;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: white;
  text-align: center;
  border-radius: 13px;
  margin-bottom: 7px;

  & div {
    height: 8px;
    border-radius: 13px;
    background-color: #276df1;
  }
}

.infoWrapper {
  display: flex;
  margin-bottom: 20px;
  font-size: 11px;

  & div:first-of-type {
    margin-right: 40px;
  }
}

.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  color: #a5a5a5;
}

.action {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  & svg {
    margin-right: 4px;
  }
}

.icon {
  margin-bottom: 40px;
}

.text {
  font-size: 20px;
  font-weight: bold;
}

.restartButton {
  margin-top: 40px;
}
