@mixin font-heading-l1 {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
}

@mixin font-heading-l2{
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
}

@mixin font-heading-l3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

@mixin font-body{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

@mixin rounded-left-border {
  border-radius: 61px 0px 0px 0px;
}