:global {
  .react-multi-carousel-dot button {
    background: #c4c4c4 !important;
    border: 0 !important;
  }
  .react-multi-carousel-dot--active button {
    background: #3bb46c !important;
    border: 0 !important;
  }
}

.carousel {
  display: none;
  position: relative;
}

.container {
  padding-bottom: 60px;
}

.item {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0px 20px;
}

@media (max-width: 1279px) {
  .carousel {
    display: block;
  }

  .container {
    padding-bottom: 40px;
  }
}
