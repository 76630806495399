.wrapper {
  margin-bottom: 50px;
  background-color: #276df1;
  margin-left: -40px;
  width: calc(100vw - 10px);
  padding: 30px 0px 40px 0px;
}

.title {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
  margin-bottom: 30px;
  color: white;
  padding-left: 40px;
}

.content {
  padding: 0px 166px;
}

.cards {
  display: flex;
  justify-content: space-between;
}

.card {
  color: white;
  width: 191px;
  position: relative;
  line-height: 17px;
}

.number {
  position: absolute;
  font-size: 70px;
  line-height: 72px;
  font-weight: bold;
  color: rgba(196, 196, 196, 0.24);
  top: -7px;
  left: -29px;
}

.flow {
  margin: 50px 0;
  margin-left: -166px;
  width: calc(100vw - 10px);
  padding: 0px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block {
  min-width: 120px;
  max-width: 120px;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
  border-radius: 26px 0px 0px 0px;
  position: relative;
}

.innerBlock {
  min-width: 120px;
  max-width: 120px;
  height: 79px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 26px 0px 0px 0px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.text {
  width: min-content;
  text-align: center;
  margin-top: 10px;
  line-height: 17px;
}

.warning {
  text-align: center;
  margin: 14px 0;
  color: #c4c4c4;
}

.hidden {
  visibility: hidden;
}

.logo {
  margin-top: 10px;
  min-width: 129px;
  max-width: 129px;
}

.arrows {
  width: 100%;
}

.arrow {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  box-shadow: inset 0 0 0 2px;
  margin: 0 40px;
  height: 7px;
  color: rgba(255, 255, 255, 0.5);

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    transform: rotate(45deg);
    right: 0;
    bottom: -3px;
  }
}

.arrowLeft {
  &::after {
    transform: rotate(225deg);
    left: 0;
  }
}

@media (max-width: 1279px) {
  .wrapper {
    margin-left: -10px;
    width: calc(100vw);
  }

  .content {
    padding: 0px 95px;
  }

  .card {
    font-size: 10px;
    line-height: 12px;
    width: 120px;
  }

  .number {
    font-size: 50px;
    line-height: 72px;
    top: -20px;
    left: -20px;
  }

  .block {
    min-width: 78px;
    max-width: 78px;
    height: 52px;
    border-radius: 9px 0px 0px 0px;
  }

  .innerBlock {
    min-width: 78px;
    max-width: 78px;
    height: 52px;
    border-radius: 9px 0px 0px 0px;
    top: 2px;
    left: 2px;
  }

  .text {
    font-size: 9px;
    line-height: 12px;
  }

  .flow {
    width: 100vw;
    margin-left: -95px;
  }

  .warning {
    margin: 13px 0px;
    font-size: 11px;
    line-height: 12px;
  }

  .arrow {
    margin: 0px 14px;
  }

  .logo {
    max-width: 66px;
    min-width: 66px;
  }

  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    margin-bottom: 30px;
    margin-left: -10px;
    width: calc(100vw);
  }

  .content {
    display: flex;
    justify-content: space-evenly;
    padding: 30px 20px;
  }

  .cards {
    padding: 70px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card {
    width: 85px;
    font-size: 9px;
    line-height: 10px;
    margin-bottom: 100px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .logo {
    min-width: 78px;
    max-width: 78px;
  }

  .number {
    top: 0;
    transform: translateY(-100%) translateY(15px);
    left: 0;
    font-size: 50px;
    line-height: 50px;
  }

  .arrow {
    margin: 0 15px;
  }

  .flow {
    width: auto;
    padding: 0;
    margin: 0 20px;
    flex-direction: column;
  }

  .arrows {
    transform: rotate(90deg) scale(1, -1);
  }

  .text {
    font-size: 9px;
    line-height: 12px;
  }

  .warning {
    transform: rotate(90deg) scale(1, -1);
    font-size: 8px;
    line-height: 10px;
    margin: 11px 15px;
    width: 45px;
  }

  .block {
    min-width: 80px;
    max-width: 80px;
    height: 52px;
    border-radius: 9px 0px 0px 0px;
  }

  .innerBlock {
    min-width: 80px;
    max-width: 80px;
    height: 52px;
    border-radius: 9px 0px 0px 0px;
    top: 2px;
    left: 2px;
  }

  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    padding: 0px 30px;
  }
}
