.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 186px;
  width: 100%;
  max-width: 186px;
  height: 115px;
  font-size: 13px;
  background-color: white;
  border-radius: 26px 0px 0px 0px;
  padding: 0px 10px;
  line-height: 17px;
  position: relative;
}

.badge {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  background-color: #3bb46c;
  color: white;
  border-radius: 3px 0px 3px 0px;
}

@media (max-width: 1279px) {
  .wrapper {
    min-width: 131px;
    font-size: 10px;
    text-align: center;
  }
}
