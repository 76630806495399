.admin-table {
    .ant-table {
        margin: 30px 0;
        background: transparent;
    }

    .ant-pagination-item {
        border-color: rgba(#000, 0.7);
    }

    .ant-pagination-item-active{
        border-color: #000;

        a {
            color: #000;
        }
    }
}

.admin-card {
    background: transparent !important;

    input.ant-input {
        background: transparent !important;
        border: none;
        border-bottom: 1px solid #000;
    }
}

@mixin user-action-btn {
    width: 20% !important;
    margin: 0 20px !important;
    border-radius: 25px !important;
}

.admin-success-button{
    border-color: #3BB46C !important;
    color: #3BB46C !important;
    border-radius: 25px !important;
}

.admin-user-success-btn,
.admin-user-danger-btn,
.admin-user-primary-btn,
.admin-user-default-btn {
    @include user-action-btn;
}

.admin-user-success-btn {
    border-color: #3BB46C !important;
    color: #3BB46C !important;
}

.admin-user-danger-btn {
    border-color: #D75033 !important;
    color: #D75033 !important;
}

.admin-user-primary-btn {
    color: #276DF1 !important;
    border-color: #276DF1 !important;
}

.admin-user-default-btn {
    color: #000 !important;
    border-color: #000 !important;
}

.admin-user-success-btn[disabled],
.admin-user-danger-btn[disabled],
.admin-user-primary-btn[disabled],
.admin-user-default-btn[disabled] {
    color: #ccc !important;
    border-color: #ccc !important; 
}

.admin-divider{
    border-top: none !important;
}