@import 'styles/mixins';
@import 'styles/colors';

.container {
  //padding: 46px 22px; to the feature
  padding: 0 22px;
}

.card {
  @include rounded-left-border;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  padding: 35px 20px 25px 20px;
  border: 1px solid $border-color;
  max-width: 300px;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
  }

  &__button {
    width: 154px;
    margin: 27px 0 0;
  }
}

.icon {
  margin-right: 18px;
  font-size: 31px;

  & svg {
    color: $primary;
  }
}

.icon.error {
  & svg {
    color: #d75033;
  }
}

.title {
  @include font-heading-l3;
  color: $black;
}

.wrapperDiagram {
  display: flex;
  gap: 30px;
  margin-bottom: 45px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
