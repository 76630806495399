.wrapper {
  background-color: white;
  border-radius: 61px 0px 0px 0px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;

  & img {
    border-radius: 61px 0px 0px 0px;
  }

  &:not(.blocked):hover {
    box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.23);

    & img {
      overflow: hidden;
      filter: brightness(70%);
    }
  }
}

.blocked {
  filter: blur(10px);
  cursor: pointer;
  user-select: none;
}

.badge {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2;
}

.image {
  width: 100%;
  height: fit-content;
  object-fit: cover;
  height: 211px;
  overflow: hidden;
  transition: all 0.6s ease;
}

.info {
  padding: 20px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 24px;
  min-height: 48px;
}

.hiddenLink {
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.chip {
  line-height: 13px;
  padding: 4px 8px;
  margin-right: 12px;
  margin-bottom: 10px;
  font-size: 11px;
  color: #3bb46c;
  border: 1px solid #3bb46c;
  border-radius: 25px;
}

.location {
  display: flex;
  color: #3bb46c;

  & svg {
    margin-right: 4px;
  }
}

.properties {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.property {
  padding: 8px 0;
  line-height: 17px;
}

.companyDescription {
  max-width: 80%;
}

.key {
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 4px;
  white-space: nowrap;
  color: #c4c4c4;
}

.value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  line-height: 21px;
  position: relative;

  & span {
    position: relative;
  }
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #e9ebfb;
}

@media (max-width: 1279px) {
  .wrapper {
    &:hover {
      box-shadow: none;

      & img {
        filter: none;
      }
    }
  }
}
