.wrapper {
  margin-bottom: 50px;
}

.card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 64px 0px 130px;
}

.cta {
  width: 340px;
  margin-top: auto;
}

.ctaSecond {
  width: 395px;
  margin-top: auto;
}

.title {
  font-size: 44px;
  font-weight: bold;
  line-height: 47px;
}

.subtitle {
  font-size: 18px;
  line-height: 21px;
  margin-top: 15px;
}

.image {
  position: relative;
  height: 250px;
  overflow: hidden;

  & img {
    height: 100%;
    width: 569px;
    border-radius: 61px 0px 0px 0px;
  }
}

.featured {
  background-color: #276df1;
  border-radius: 8px 0px 8px 0px;
  margin-bottom: 14px;
  width: fit-content;
  padding: 2px 8px;
  color: white;
}

.badge {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2;
}

.center {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 195px;
  background-color: #276df1;
  border-radius: 26px 0px 0px 0px;
  padding: 12px;
  color: white;
  font-size: 14px;
  line-height: 17px;
  z-index: 2;
}

.inside {
  padding: 10px 14px;
  border-radius: 26px 0px 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 20px;
}

.buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1279px) {
  .card {
    padding: 0px 10px 0px 75px;
  }

  .title {
    font-size: 30px;
    line-height: 32px;
  }

  .image {
    height: 200px;
    margin-left: 15px;
    min-width: 390px;
    max-width: 390px;

    & img {
      width: 100%;
    }
  }

  .cta {
    width: 290px;
    margin-bottom: 0px;
  }

  .ctaSecond {
    width: 290px;
    margin-bottom: 0px;
  }

  .center {
    width: 150px;
  }

  .inside {
    font-size: 10px;
    line-height: 12px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    margin-bottom: 30px;
  }

  .card {
    display: block;
    padding: 0;
    margin-top: auto;
  }

  .center {
    display: none;
  }

  .title {
    font-size: 30px;
    line-height: 32px;
  }

  .cta {
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 20px;
  }

  .ctaSecond {
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 20px;
  }

  .buttons {
    margin-top: 20px;
  }

  .image {
    height: 160px;
    margin: 0 auto;
    min-width: auto;
    max-width: auto;

    & img {
      width: 100%;
    }
  }
}
