.wrapper {
  display: flex;
  justify-content: space-between;
}

.form {
  min-width: 479px;
  width: 100%;
  margin-right: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar {
  min-width: 296px;
  border: 1px solid #e3e4e4;
  border-radius: 61px 0px 0px 0px;
  background-color: white;
  padding: 50px 0px 30px 0px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  margin-bottom: 40px;
  width: 100%;
}

.avatar {
  border-radius: 50%;
  width: 159px;
  height: 159px;
  background-color: #a5a5a5;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 40px;

  & img {
    width: 100%;
    height: 100%;
  }
}

.actions {
  margin-bottom: 50px;
}

.action {
  display: flex;
  align-items: center;
  color: #276df1;
  cursor: pointer;

  & svg {
    margin-right: 14px;
    min-width: 16px;
    max-width: 16px;
  }

  &:first-child {
    margin-bottom: 12px;
  }
}

.button {
  width: 100%;
  margin-bottom: 60px;
}

.checkbox {
  align-items: flex-start !important;
}

:global {
  .selected-flag {
    padding-left: 0 !important;
    background-color: transparent !important;
  }

  .ant-select-selector {
    border: 0px !important;
    border-bottom: 1px solid black !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding-left: 0px !important;
    background-color: transparent !important;
  }

  .ant-select-selection-placeholder {
    color: #757575 !important;
  }

  .ant-select-selection-search {
    left: 0 !important;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 30px;
}

.row {
  display: flex;

  & div:not(:last-child) {
    margin-right: 30px;
  }
}

.select {
  width: 100% !important;
  margin-top: 9px !important;
  height: fit-content !important;
}

.input {
  display: block;
  width: 100%;
}

.dropdown {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-bottom: 1px !important;
}

.phoneInput {
  width: 100% !important;
  min-width: 205px !important;
  border: 0 !important;
  margin-top: 6px !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  color: black !important;
  padding-left: 40px !important;
  background-color: transparent !important;

  transition: 0.3s ease-out all !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  &:hover {
    color: black !important;
    border-bottom: 1px solid #276df1 !important;

    &::placeholder {
      color: #757575 !important;
    }
  }

  &:focus {
    color: black !important;
    border-bottom: 1px solid #276df1 !important;

    &::placeholder {
      color: #757575 !important;
    }
  }
}

.disabledSelect {
  :global {
    .ant-select-selector {
      border-color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;

  &:hover {
    color: rgba(0, 0, 0, 0.25) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  margin: 0 auto;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.inputError {
  color: #d75033 !important;
  border-bottom-color: #d75033 !important;

  &::placeholder {
    color: #d75033 !important;
  }
}

.errorsMobile {
  display: none;
}

.error {
  display: flex;
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateX(100%) translateY(-50%);
  line-height: 17px;
  background-color: #e9ebfb;
  padding: 10px 20px;
  border-radius: 26px 0px 0px 0px;
}

.circle {
  min-width: 11px;
  max-width: 11px;
  min-height: 11px;
  max-height: 11px;
  background-color: #d75033;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
}

.link {
  color: #276df1;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-out;

  &:hover {
    color: #276df1;
    border-bottom: 1px solid #276df1;
  }
}

.changePasswordButton{
  margin: 55px 0;
}

@media (max-width: 1279px) {
  .error {
    display: none;
  }

  .errorsMobile {
    display: block;
    line-height: 17px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
  }

  .errorMobile {
    display: flex;
    font-size: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 1200px) {
  .wrapper{
    flex-direction: column-reverse;
  }

  .block{
    margin-top: 50px;
  }

  .form{
    min-width: auto;
  }

  .sidebar{
    min-width: auto;
  }
}

@media (max-width: 767px) {
  .row {
    display: block;

    & div:not(:last-child) {
      margin-right: 0px;
    }
  }

  .select {
    width: 100% !important;
  }
}

