.titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  & div:first-child {
    margin-right: 50px;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.modalTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 60px;
}

.header {
  display: grid;
  grid-template-columns: 32% 20% 15% 15% 18%;
  padding: 20px 0;
  border-bottom: 3px solid #a5a5a5;
  color: #727171;
}

@media (max-width: 767px) {
  .header {
    display: none;
  }

  .titleWrapper {
    justify-content: space-between;
  }
}
