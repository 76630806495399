.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 30px;
  text-align: center;
}

.icon {
  margin-bottom: 16px;
}

.resend {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin-top: 30px;
}

.link {
  color: #276df1;
  font-size: 12px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: #276df1;
    border-bottom: 2px solid #276df1;
  }
}

.green {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 30px;
  text-align: center;
  color: #3bb46c;
}

.text {
  text-align: center;
  line-height: 17px;

  & div:first-child {
    margin-bottom: 30px;
  }

  & div:last-child {
    margin-bottom: 40px;
  }
}

.button {
  width: 222px;
}

@media (max-width: 1279px) {
  .text {
    width: 100%;
  }
}
