.wrapper {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f2f6;
}

@media (max-width: 1279px) {
  .wrapper {
    padding: 10px 20px 40px 20px;
  }
}
