.wrapper{
    background: #fff;
    padding: 30px;
    border-radius: 61px 0px 0px 0px;
}

.content {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    border-radius: 61px 0px 0px 0px;
    width: 100%;
  }
  
  .form {
    width: 222px;
  }
  
.title {
font-size: 20px;
font-weight: bold;
line-height: 24px;
margin-bottom: 20px;
text-align: center;
}

.inputWrapper {
    position: relative;
    margin: 50px 0 0;
}

.action {
display: flex;
font-size: 12px;
margin-top: 30px;

& div:first-child {
    margin-right: 7px;
}
}

.input {
    display: block;
    width: 100%;
}

.inputError {
color: #d75033;
border-bottom-color: #d75033;

&::placeholder {
    color: #d75033;
}
}

.errorsMobile {
display: none;
}

.error {
display: flex;
position: absolute;
top: 0%;
right: -16px;
transform: translateX(100%) translateY(0%);
line-height: 17px;
background-color: #e9ebfb;
padding: 10px 20px;
border-radius: 26px 0px 0px 0px;
}

.circle {
min-width: 11px;
max-width: 11px;
min-height: 11px;
max-height: 11px;
background-color: #d75033;
border-radius: 50%;
margin-right: 10px;
margin-top: 3px;
}

.btn {
    margin-top: 30px;
    width: 100%;
}

.link {
color: #276df1;
font-size: 12px;
border-bottom: 2px solid transparent;
transition: all 0.3s ease-out;

&:hover {
    color: #276df1;
    border-bottom: 2px solid #276df1;
}
}

.resendLink {
color: #276df1;
font-size: 12px;
border-bottom: 2px solid transparent;
transition: all 0.3s ease-out;
cursor: pointer;
width: fit-content;
margin-top: 10px;

&:hover {
    color: #276df1;
    border-bottom: 2px solid #276df1;
}
}

@media (max-width: 1279px) {
.wrapper {
    padding: 10px 20px 40px 20px;
}

.error {
    display: none;
}

.errorsMobile {
    display: block;
    line-height: 17px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
}

.errorMobile {
    display: flex;
    font-size: 10px;
    margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }
}
