.wrapper {
  margin-bottom: 50px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 0;
}

.title {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
  margin-bottom: 30px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  user-select: none;
  height: fit-content;
  line-height: 13px;
  padding: 4px 14px;
  margin-bottom: 12px;
  margin-right: 12px;
  font-size: 11px;
  color: #727171;
  border: 1px solid #727171;
  border-radius: 25px;
  cursor: pointer;
  white-space: nowrap;
}

.active {
  color: #3bb46c;
  border-color: #3bb46c;
}

.controls {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 40px;
}

.filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 100px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 40px;
  column-gap: 30px;
}

.filter {
  display: flex;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
}

.filtersButton {
  display: none;
}

.button {
  margin: 0 auto;
}

.name {
  margin-right: 16px;
  white-space: nowrap;
}

.opened {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(115, 115, 115, 0.5);
  z-index: 9999;
}

.modal {
  position: relative;
  background: #ffffff;
  overflow: auto;
  outline: none;
  max-width: 450px;
  height: 100%;
  padding: 80px 40px 40px 40px;
  z-index: 9999;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
}

.video {
  width: 100%;
}

@media (max-width: 1279px) {
  .wrapper {
    margin-bottom: 30px;
  }

  .filters {
    display: none;
  }

  .filter {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .name {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .padding {
    padding: 0px 20px;
  }

  .filtersButton {
    display: block;
  }

  .cards {
    display: none;
  }

  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}


