.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f1f2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  & svg {
    animation: leaves 1s ease-in-out infinite alternate;
  }
}

@keyframes leaves {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
