:global {
  .selected-flag {
    padding-left: 0 !important;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.inputs {
  display: flex;
  margin-bottom: 10px;

  & div:first-child {
    margin-right: 40px;
  }
}

.input {
  display: block;
  width: 222px;
}

.dropdown {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin-bottom: 1px !important;
}

.phoneInput {
  width: 100% !important;
  border: 0 !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  color: black !important;
  padding-left: 40px !important;

  transition: 0.3s ease-out all !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  &:hover {
    color: black !important;
    border-bottom: 1px solid #276df1 !important;

    &::placeholder {
      color: #757575 !important;
    }
  }

  &:focus {
    color: black !important;
    border-bottom: 1px solid #276df1 !important;

    &::placeholder {
      color: #757575 !important;
    }
  }
}

.checkbox {
  align-items: flex-start !important;
  font-size: 11px !important;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.inputError {
  color: #d75033 !important;
  border-bottom-color: #d75033 !important;

  &::placeholder {
    color: #d75033 !important;
  }
}

.errorsMobile {
  display: none;
}

.error {
  display: flex;
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateX(100%) translateY(-50%);
  line-height: 17px;
  background-color: #e9ebfb;
  padding: 10px 20px;
  border-radius: 26px 0px 0px 0px;
}

.circle {
  min-width: 11px;
  max-width: 11px;
  min-height: 11px;
  max-height: 11px;
  background-color: #d75033;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
}

.button {
  width: 222px;
}

.link {
  color: #276df1;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-out;

  &:hover {
    color: #276df1;
    border-bottom: 1px solid #276df1;
  }
}

@media (max-width: 1279px) {
  .error {
    display: none;
  }

  .checkbox {
    font-size: 8px !important;
  }

  .errorsMobile {
    display: block;
    line-height: 17px;
    background-color: #e9ebfb;
    padding: 10px 20px;
    border-radius: 26px 0px 0px 0px;
  }

  .errorMobile {
    display: flex;
    font-size: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}
