.wrapper {
  margin-bottom: 50px;
  padding: 0px 10px;
}

.title {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
  margin-bottom: 30px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 32%;
  background-color: white;
  padding: 60px 50px 40px 50px;
  transition: 0.3s ease-out all;

  &:hover {
    background-color: #3bb46c;
    color: white;

    & ul {
      color: white;
    }
  }

  & ul {
    margin-bottom: 0;
    list-style-type: '- ';
    padding: 0;
    padding-left: 20px;
    color: #828282;
    transition: 0.3s ease-out all;

    & li {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.columnTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 1279px) {
  .column {
    padding: 30px 10px;

    & ul {
      font-size: 10px;
      line-height: 12px;
      padding-left: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .columnTitle {
    margin-bottom: 20px;
    font-size: 19px;
  }

  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    padding: 0px 10px;
  }

  .row {
    display: block;
  }

  .columnTitle {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .column {
    width: 100%;
    padding: 30px 20px 20px 20px;
    margin-bottom: 10px;

    & ul {
      font-size: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
