.wrapper {
  width: auto;
  margin-left: 12px;
  padding: 2px 7px;
  height: fit-content;
  display: flex;
  line-height: 17px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  background-color: white;
  color: black;
  border-radius: 3px 0px 3px 0px;
}

.circle {
  margin-right: 3px;
  width: 6px;
  height: 6px;
  background-color: #3bb46c;
  border-radius: 50%;
}

@media (max-width: 1279px) {
  .wrapper {
    display: inline-flex;
    align-items: baseline;
    font-size: 10px;
    margin-left: 0;
  }
}
