@import 'styles/mixins';
@import 'styles/colors';

.list {
  margin: 0 0 25px;
  padding: 0;
  list-style: none;
  font-size: 14px;
  color: $text-color-light;
  border-left: 2px solid #ffffff;
}

.item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 26px;
  column-gap: 40px;
  place-items: center;
  white-space: nowrap;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
  
  &:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: $primary;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: -4px;
  }

  &:not(.item:last-child) {
    margin-bottom: 22px;
  }
}

.status {
  width: 100px;
  line-height: 22px;
  text-align: center;
  border: 1px solid $black;
  border-radius: 25px;
}

.late {
  color: $red;
  border-color: $red;
}

.draft {
  color: #c4c4c4;
  border-color: #c4c4c4;
}

.paid {
  color: $primary;
  border-color: $primary;
}

.pag {
  display: flex;
  justify-content: center;
}