@media (max-width: 479px) {
  .onfido-sdk-ui-Modal-inner {
    position: relative !important;
    width: 94vw !important;
    max-width: 32em !important;
    height: 37.5em !important;
  }
}

@media screen and (max-width: 530px){
  .ant-steps.ant-steps-horizontal{
    .ant-steps-item-content{
      width: 55px;
    }

    .ant-steps-item-icon{
      margin-left: 20px !important;
    }

    .ant-steps-item-tail{
      margin-left: 30px !important;
    }
  }
}

.admin-image-uploader-main .ant-upload {
  width: 300px;
  height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.admin-image-uploader-default .ant-upload{
  width: 150px;
  height: 150px;
}