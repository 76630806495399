.wrapper {
  display: flex;
}

.title {
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;
  margin-right: 50px;
}

.icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
  justify-items: center;
  grid-row-gap: 1vw;
  grid-column-gap: 2vw;

  & img {
    width: 8vw;
    mix-blend-mode: multiply;
    min-width: 70px;
  }
}

@media (max-width: 1279px) {
  .wrapper {
    flex-direction: column;
  }

  .title {
    margin-bottom: 20px;
    margin-right: 0;
    padding-left: 20px;
    font-size: 30px;
    line-height: 36px;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    padding: 0px 20px;
  }

  .wrapper {
    display: block;
  }

  .icons {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 2vw;
  }
}
