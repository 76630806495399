.wrapper {
  display: flex;
  background-color: white;
  border-radius: 61px 0px 0px 0px;
  padding: 35px 20px 25px 20px;
  border: 1px solid #e3e4e4;
  max-width: 310px;
  width: 100%;
}

.icon {
  margin-right: 18px;
  font-size: 31px;

  & svg {
    color: #3bb46c;
  }
}

.red {
  & svg {
    color: #d75033;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 16px;
}

.status {
  color: #a5a5a5;
  margin-bottom: 14px;
}

.button {
  width: 100%;
}

@media (max-width: 767px) {
  .title {
    font-size: 14px;
    line-height: 17px;
  }

  .status {
    font-size: 10px;
    line-height: 12px;
  }

  .wrapper {
    padding: 30px 15px;
  }
}
