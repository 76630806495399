.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 200px 50px 80px 50px;
  background-color: #f1f2f6;
  background-image: url(../../assets/images/wave.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: 0;
}

.profileWrapper {
  padding: 20px 50px 20px 50px;
  background-image: none;
  border-top: 1px solid #c4c4c4;
}

.column {
  width: 31%;
}

.title {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  width: 240px;
  margin-bottom: 64px;
}

.icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info {
  text-align: left;
  line-height: 17px;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.input {
  width: 100%;
  margin-right: 28px;
}

.form {
  display: flex;
  margin-bottom: 30px;
}

.links {
  display: flex;
}

.block {
  margin-right: 90px;
}

.link {
  display: block;
  color: black;
  text-decoration: underline;

  &:hover {
    color: #276df1;
  }
}

@media (max-width: 1279px) {
  .wrapper {
    padding: 200px 40px 80px 40px;
  }

  .profileWrapper {
    padding: 45px;
  }

  .link {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .form {
    margin-bottom: 36px;
  }

  .input {
    width: 100%;
    margin-right: 4px;
  }

  .block {
    margin-right: 40px;
  }

  .info {
    font-size: 11px;
    line-height: 13px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 65px 30px;
    flex-direction: column;
    background-position-x: 30%;
  }

  .profileWrapper {
    padding: 40px 30px 60px 30px;
  }

  .left {
    width: 100%;
    margin-bottom: 40px;
  }

  .column {
    width: 100%;
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .right {
    width: 100%;
  }

  .form {
    display: flex;
    margin-bottom: 40px;
  }

  .content {
    width: 100%;
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  .info {
    font-size: 10px;
    line-height: 12px;
  }

  .icons {
    margin-bottom: 0px;
  }

  .input {
    width: 100%;
    margin-right: 4px;
  }

  .block {
    margin-right: 40px;
    width: 50%;
  }

  .link {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .firstCertificate {
    width: 73px;
  }

  .secondCertificate {
    width: 135px;
  }

  .title {
    width: 100%;
    margin-bottom: 35px;
  }
}
