@font-face {
  font-family: 'Museo Sans';
  font-weight: 100;
  src: url(../assets/fonts/MuseoSansCyrl.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 300;
  src: url(../assets/fonts/MuseoSansCyrl_0.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: normal;
  src: url(../assets/fonts/MuseoSansCyrl_1.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: bold;
  src: url(../assets/fonts/MuseoSansCyrl_2.otf) format('opentype');
}
