.content {
  padding: 60px 40px;
  background-color: #f1f2f6;
  background-image: url(../../assets/images/wave.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: 190px;
}

@media (max-width: 1279px) {
  .content {
    padding: 20px 10px;
    background-position-y: 130px;
  }
}
